import React from "react";
import Loader from "./Loader";

const Header = () => {
    return (
        <>
            <Loader />
            <header className="fixed-top header-fullpage top-border top-transparent wow fadeInDown">
                <div className="top-bar-right d-flex align-items-center text-md-left">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col">
                                <i className="icofont-google-map"></i> Situé à 510, RUE JOFFRE AKWA, DOUALA
                            </div>
                            <div className="col-md-auto">
                                <span className="mr-3"><i className="icofont-ui-touch-phone"></i> +237 690 768 400</span>
                                <span className="mr-3"><i className="icofont-ui-email"></i> Email info@log2trans.com</span>
                                {/* <div className="dropdown d-inline-flex lang-toggle shadow-sm">
                                    <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-hover="dropdown" data-animations="slideInUp slideInUp slideInUp slideInUp">
                                        <img src="images/us.svg" alt="" className="dropdown-item-icon" />
                                        <span className="d-inline-block d-lg-none">US</span>
                                        <span className="d-none d-lg-inline-block">United States</span> <i className="icofont-rounded-down"></i>
                                    </a>
                                    <div className="dropdown-menu dropdownhover-bottom dropdown-menu-right" role="menu">
                                        <a className="dropdown-item active" href="#">English</a>
										<a className="dropdown-item" href="#">Français</a>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <nav className="navbar navbar-expand-lg bg-transparent">
                    <div className="container text-nowrap">
                        <div className="d-flex align-items-center w-100 col p-0">
                            <a className="navbar-brand rounded-bottom light-bg" href="/">
                                <img src="images/logo.png" alt="" />
                            </a>
                        </div>
                        <div className="collapse navbar-collapse" id="navbarCollapse" data-hover="dropdown" data-animations="slideInUp slideInUp slideInUp slideInUp">
                            <ul className="navbar-nav ml-auto">
								<li className="nav-item">
									<a className="nav-link" href="/">Accueil</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="about">A propos de nous</a>
								</li>
                                <li className="nav-item">
                                    <a className="nav-link" href="services">Services</a>
                                </li>
								<li className="nav-item">
									<a className="nav-link" href="contact">Contactez nous</a>
								</li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    );
};

export default Header;

import React from "react";

const Content = () => {
    return (
        <>
            <main id="body-content">
                <section className="wide-tb-80 pb-0">
                    <div className="container pos-rel">
                        <div className="row align-items-center">
                            <div className="col-md-6 wow fadeInLeft" data-wow-duration="0" data-wow-delay="0s">
                                <h2 className="mb-4 fw-7 txt-blue">
                                    A propos de <span className="fw-6 txt-orange">Log2Trans</span>
                                </h2>
                                <p>Nous sommes Commissionnaire Agrée en Douanes sous le numéro 6729/MINFI/DGD3/CDL depuis
                                2013. </p>

                                <p>Nos prestations sont effectuées depuis sous palan Douala, Kribi et Yaoundé rendu à votre site ou toute
                                autre destination de votre choix, non dépoté, dédouané, hors droits et taxes de douane, avec
                                assurance locale exigible.</p>
                                <p>Nous sommes spécialistes en : </p>
                                <ul className="list-unstyled icons-listing theme-orange mb-0 ml-2">
                                    <li><i className="icofont-dotted-right"></i>Commission en Douane</li>
                                    <li><i className="icofont-dotted-right"></i>Logistique industrielle et pétrolière</li>
                                    <li><i className="icofont-dotted-right"></i>Transport et Manutention</li>
                                    <li><i className="icofont-dotted-right"></i>Intermédiation dans le commerce international</li>
                                    <li><i className="icofont-dotted-right"></i>Conseil en achat/vente à l’international</li>
                                    <li><i className="icofont-dotted-right"></i>Conseil en procédures douanières.</li>
                                </ul>
                                <p>Notre force est de pouvoir s'associer, si besoin, aux compétences des sociétés partenaires spécialisées. </p>
                            </div>
                            <div className="col-md-6 wow fadeInRight" data-wow-duration="0" data-wow-delay="0s">
                                <img src="images/map-bg-orange.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="bg-light-gray wide-tb-100 pb-5 why-choose">
                    <div className="container pos-rel">
                        <div className="row">
                            <div className="col-sm-12 wow fadeInDown" data-wow-duration="0" data-wow-delay="0s">
                                <h1 className="heading-main">
                                    <span>Our Goodness</span>
                                    What Makes Us Special
                                </h1>
                            </div>
                            <div className="col-12 col-lg-4 wow fadeInUp" data-wow-duration="0" data-wow-delay="0s">
                                <div className="icon-box-2">
                                    <div className="media">
                                        <div className="service-icon">
                                            <i className="icofont-id"></i>
                                        </div>
                                        <div className="service-inner-content media-body">
                                            <h4 className="h4-md">Trusted Franchise</h4>
                                            <p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis. Vivamus ac ultrices diam, vitae accumsan tellus.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.2s">
                                <div className="icon-box-2">
                                    <div className="media">
                                        <div className="service-icon">
                                            <i className="icofont-live-support"></i>
                                        </div>
                                        <div className="service-inner-content media-body">
                                            <h4 className="h4-md">Customer Support</h4>
                                            <p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis. Vivamus ac ultrices diam, vitae accumsan tellus.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.4s">
                                <div className="icon-box-2">
                                    <div className="media">
                                        <div className="service-icon">
                                            <i className="icofont-history"></i>
                                        </div>
                                        <div className="service-inner-content media-body">
                                            <h4 className="h4-md">Reliability & Punctuality</h4>
                                            <p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis. Vivamus ac ultrices diam, vitae accumsan tellus.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* <section className="wide-tb-60 p-0 bg-light-gray">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 wow pulse animated" data-wow-duration="0" data-wow-delay="0.7s">
                                <div className="bg-fixed pos-rel video-popup">
                                    <div className="bg-overlay black opacity-50"></div>
                                    <div className="zindex-fixed pos-rel">
                                        <a href="#" className="play-video"><i className="icofont-play icofont-4x"></i></a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section> */}
                <section className="wide-tb-100 mb-spacer-md">
                    <div className="container pb-0">
                        <div className="row d-flex align-items-center">
                            <div className="col col-12 col-lg-3 col-sm-6 wow slideInUp" data-wow-duration="0" data-wow-delay="0s">
                                <div className="counter-style-1 light-bg">
                                    <p className="mb-1"><i className="icofont-google-map"></i></p>
                                    <span className="counter">15</span>
                                    <div>
                                        Sites
                                    </div>
                                </div>
                            </div>
                            <div className="col col-12 col-lg-3 col-sm-6 wow slideInUp" data-wow-duration="0" data-wow-delay="0.3s">
                                <div className="counter-style-1 light-bg">
                                    <p className="mb-1"><i className="icofont-globe"></i></p>
                                    <span className="counter">110</span>
                                    <span>+</span>
                                    <div>
                                        Clients
                                    </div>
                                </div>
                            </div>
                            <div className="w-100 d-none d-sm-block d-lg-none spacer-60"></div>
                            <div className="col col-12 col-lg-3 col-sm-6 wow slideInUp" data-wow-duration="0" data-wow-delay="0.6s">
                                <div className="counter-style-1 light-bg">
                                    <p className="mb-1"><i className="icofont-vehicle-delivery-van"></i></p>
                                    <span className="counter">240</span>
                                    <span>+</span>
                                    <div>
                                        Véhicles
                                    </div>
                                </div>
                            </div>
                            <div className="col col-12 col-lg-3 col-sm-6 wow slideInUp" data-wow-duration="0" data-wow-delay="0.9s">
                                <div className="counter-style-1 light-bg">
                                    <p className="mb-1"><i className="icofont-umbrella-alt"></i></p>
                                    <span className="counter">2340</span>
                                    <div>
                                        Tonnes Transportées
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="pos-rel bg-sky-blue">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-lg-5 col-12 p-0">
                                <img src="images/why-choose-us.jpg" className="w-100" alt="" />
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className="p-5 about-whoose">
                                    <h1 className="heading-main text-left mb-4">
                                        <span>Pourquoi choisir</span>
                                        Log2Trans
                                    </h1>
                                    <ul className="list-unstyled icons-listing theme-orange mb-0">
                                        <li className="wow fadeInUp" data-wow-duration="0" data-wow-delay="0s"><i className="icofont-check"></i>La mise à disposition d’informations en temps réel relatives à l’évolution de vos dossiers et la position de vos marchandises</li>
                                        <li className="wow fadeInUp" data-wow-duration="0" data-wow-delay="0.1s"><i className="icofont-check"></i>La maîtrise de vos coûts de revient</li>
                                        <li className="wow fadeInUp" data-wow-duration="0" data-wow-delay="0.2s"><i className="icofont-check"></i>L’optimisation des procédures douanières</li>
                                        <li className="wow fadeInUp" data-wow-duration="0" data-wow-delay="0.3s"><i className="icofont-check"></i>La négociation des facilités douanières</li>
                                        <li className="wow fadeInUp" data-wow-duration="0" data-wow-delay="0.4s"><i className="icofont-check"></i>Gestion du transfert des marchandises de site à l’autre</li>
                                        <li className="wow fadeInUp" data-wow-duration="0" data-wow-delay="0.5s"><i className="icofont-check"></i>La maîtrise des risques en commerce international</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wide-tb-100 pb-0 team-section-bottom pos-rel">
                    <div className="container">
                        <div className="col-sm-12">
                            <h1 className="heading-main">
                                <span>Les faces derrière Log2Trans</span>
                                Notre Equipe
                            </h1>
                        </div>
                        <div className="row pb-4">
                            <div className="col-sm-12 col-md-4 wow fadeInUp" data-wow-duration="0" data-wow-delay="0s">
                                <div className="team-section-two">
                                    <img src="images/team/team1.jpeg" alt="" className="rounded" />
                                    <h4 className="h4-md txt-orange">Richard Didier EKENGLO ENOPA</h4>
                                    <h5 className="h5-md txt-ligt-gray">Directeur Général Adjoint</h5>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.1s">
                                <div className="team-section-two">
                                    <img src="images/team/team2.jpeg" alt="" className="rounded" />
                                    <h4 className="h4-md txt-orange">Pierre Steve OTYAM</h4>
                                    <h5 className="h5-md txt-ligt-gray">Responsable Technique Logistique et Transport</h5>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.2s">
                                <div className="team-section-two">
                                    <img src="images/team/team3.jpeg" alt="" className="rounded" />
                                    <h4 className="h4-md txt-orange">Emerant KENDA</h4>
                                    <h5 className="h5-md txt-ligt-gray">Responsable Administratif Comptable et Financière</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wide-tb-80 pb-0 team-section-bottom pos-rel">
                    <div className="container">
                        <div className="col-sm-12">
                            <h1 className="heading-main mb-0">
                                Organigramme
                            </h1>
                        </div>
                        <div className="row pb-4">
                            <div className="col-sm-12 col-md-12 wow fadeInUp" data-wow-duration="0" data-wow-delay="0s">
                                <img src="images/organigramme.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-white wide-tb-100 mb-spacer-md">
                    <div className="container">
                        <div className="col-sm-12">
                            <h1 className="heading-main">
                                <span>Nous </span>
                                Contacter
                            </h1>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 wow fadeInRight" data-wow-duration="0" data-wow-delay="0.2s">
                                <div className="align-self-stretch h-100 align-items-center d-flex bg-with-text">
                                    Que vous ayez besoin d'une distribution ou d'une exécution, d'une expédition de fret 
                                    définie ou d'une solution complète de chaîne d'approvisionnement, nous sommes là pour vous.
                                </div>
                            </div>
                            <div className="w-100 d-none d-sm-block d-lg-none spacer-60"></div>
                            <div className="col-lg-8 wow fadeInLeft" data-wow-duration="0" data-wow-delay="0.2s">
                                <form action="#" method="post" noValidate="novalidate" className="rounded-field gray-field">
                                    <div className="form-row mb-4">
                                        <div className="col">
                                            <input type="text" name="name" className="form-control" placeholder="Your Name" />
                                        </div>
                                        <div className="col">
                                            <input type="text" name="email" className="form-control" placeholder="Email" />
                                        </div>
                                    </div>
                                    <div className="form-row mb-4">
                                        <div className="col">
                                            <select title="Please choose a package" required="" name="package" className="custom-select" aria-required="true" aria-invalid="false">
                                                <option value="">Transport Type</option>
                                                <option value="Type 1">Type 1</option>
                                                <option value="Type 2">Type 2</option>
                                                <option value="Type 3">Type 3</option>
                                                <option value="Type 4">Type 4</option>
                                            </select>
                                        </div>
                                        <div className="col">
                                            <select title="Please choose a package" required="" name="package" className="custom-select" aria-required="true" aria-invalid="false">
                                                <option value="">Type of freight</option>
                                                <option value="Type 1">Type 1</option>
                                                <option value="Type 2">Type 2</option>
                                                <option value="Type 3">Type 3</option>
                                                <option value="Type 4">Type 4</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-row mb-4">
                                        <div className="col">
                                            <textarea rows="7" placeholder="Message" className="form-control"></textarea>
                                        </div>
                                    </div>
                                    <div className="form-row text-center">
                                        <button type="submit" className="form-btn mx-auto btn-theme bg-secondary">Envoyer <i className="icofont-rounded-right"></i></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wide-tb-100 bg-fixed clients-bg pos-rel">
                    <div className="bg-overlay blue opacity-80"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 wow fadeInDown" data-wow-duration="0" data-wow-delay="0s">
                                <h1 className="heading-main">
                                    <span>Quelques de nos</span>
                                    Clients
                                </h1>
                            </div>
                            <div className="col-sm-12 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.2s">
                                <div className="owl-carousel owl-theme d-flex relative" id="home-clients">
                                    <div className="item d-flex">
                                        <img src="images/clients/africacontainershipping.jpg" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="images/clients/asecna.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="images/clients/btc.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="images/clients/gulfcam.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="images/clients/moleculesgas.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="images/clients/novaplast.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="images/clients/parlym.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wide-tb-100 faqs">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h1 className="heading-main">
                                    <span>Foire Aux</span>
                                    Questions
                                </h1>
                            </div>
                            <div className="col-sm-12 col-md-6 wow fadeInUp" data-wow-duration="0" data-wow-delay="0s">
                                <h4 className="h4-md mb-3">Qu'est-ce qu'un commissionnaire agréé en douane et en quoi consiste votre
                                 service de commission en douane ?</h4>
                                <p>Un commissionnaire agréé en douane est une entreprise spécialisée dans les formalités douanières
                                 liées à l'importation et à l'exportation de marchandises. Nous agissons en tant qu'intermédiaire 
                                 entre les entreprises et les autorités douanières, nous chargeant de la préparation et de la 
                                 soumission des documents nécessaires pour le dédouanement des marchandises.</p>
                            </div>
                            <div className="col-sm-12 col-md-6 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.2s">
                                <h4 className="h4-md mb-3">Quelles sont les zones géographiques que vous servez et quels sont vos points
                                 d'ancrage principaux ?</h4>
                                <p>Nous opérons principalement dans les régions de Douala, Kribi et Yaoundé, mais nous pouvons également
                                 desservir d'autres destinations selon les besoins de nos clients. Nos points d'ancrage principaux sont
                                  nos bureaux situés dans ces zones, d'où nous coordonnons nos opérations logistiques et douanières.</p>
                            </div>
                            <div className="col-sm-12 col-md-6 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.4s">
                                <h4 className="h4-md mb-3">Quels sont les services de logistique industrielle et pétrolière que vous proposez ?</h4>
                                <p>Nous offrons une gamme complète de services de logistique industrielle et pétrolière, comprenant 
                                le transport, la manutention, le stockage et la distribution de marchandises pour les industries
                                 manufacturières et pétrolières. Nous mettons à disposition notre expertise pour répondre aux 
                                 besoins spécifiques de nos clients dans ces secteurs.</p>
                            </div>
                            <div className="col-sm-12 col-md-6 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.6s">
                                <h4 className="h4-md mb-3">Pouvez-vous détailler vos services de transport et de manutention ?</h4>
                                <p>Nous proposons des services de transport et de manutention pour le déplacement sécurisé et efficace de 
                                marchandises, qu'il s'agisse de chargement, de déchargement, de transfert entre les ports ou les entrepôts, 
                                ou de tout autre besoin de déplacement de marchandises.</p>
                            </div>
                            <div className="col-sm-12 col-md-6 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.8s">
                                <h4 className="h4-md mb-3">En quoi consiste votre service d'intermédiation dans le commerce international ?</h4>
                                <p>Notre service d'intermédiation dans le commerce international consiste à faciliter les transactions 
                                commerciales entre des parties situées dans des pays différents. Nous mettons en relation les acheteurs 
                                et les vendeurs, nous nous occupons des aspects logistiques et douaniers, et nous veillons à ce que les 
                                transactions se déroulent de manière fluide et conforme aux réglementations en vigueur.</p>
                            </div>
                            <div className="col-sm-12 col-md-6 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.9s">
                                <h4 className="h4-md mb-3">Offrez-vous des conseils en achat/vente à l'international et en procédures douanières ?</h4>
                                <p>Oui, nous proposons des services de conseil en achat/vente à l'international pour aider nos clients à prendre des 
                                décisions éclairées lorsqu'ils achètent ou vendent des produits à l'étranger. De plus, nous offrons des conseils spécialisés
                                 en matière de procédures douanières pour garantir la conformité réglementaire et optimiser les processus d'importation et 
                                 d'exportation.</p>
                            </div>
                            <div className="col-sm-12 col-md-6 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.8s">
                                <h4 className="h4-md mb-3">Comment puis-je bénéficier de vos services ?</h4>
                                <p>Pour bénéficier de nos services, il vous suffit de nous contacter par téléphone, par e-mail ou en remplissant le formulaire 
                                de contact sur notre site web. Notre équipe se fera un plaisir de discuter de vos besoins spécifiques et de vous proposer des 
                                solutions adaptées à vos exigences.</p>
                            </div>
                            <div className="col-sm-12 col-md-6 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.9s">
                                <h4 className="h4-md mb-3">Quels sont les avantages de travailler avec votre entreprise ?</h4>
                                <p>Les avantages de travailler avec notre entreprise incluent notre expertise approfondie dans les domaines de la logistique, du 
                                commerce international et des procédures douanières, ainsi que notre engagement à fournir des services de haute qualité, fiables
                                et personnalisés à chaque client. De plus, notre capacité à nous associer à des partenaires spécialisés nous permet d'offrir des 
                                solutions complètes et intégrées à nos clients.</p>
                            </div>
                            <div className="col-sm-12 col-md-6 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.8s">
                                <h4 className="h4-md mb-3">Comment puis-je obtenir un devis pour vos services ?</h4>
                                <p>Pour obtenir un devis personnalisé, veuillez nous contacter en nous fournissant des détails sur vos besoins spécifiques, tels 
                                que le type de marchandises, les quantités, les destinations, etc. Notre équipe évaluera vos besoins et vous fournira un devis 
                                détaillé et compétitif dans les plus brefs délais.</p>
                            </div>
                            <div className="col-sm-12 col-md-6 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.9s">
                                <h4 className="h4-md mb-3">Où puis-je trouver plus d'informations sur votre entreprise et vos services ?</h4>
                                <p>Vous pouvez trouver plus d'informations sur notre entreprise et nos services sur notre site web, où nous présentons 
                                nos offres détaillées ainsi que des témoignages de clients satisfaits. N'hésitez pas à nous contacter si vous avez des 
                                questions supplémentaires ou si vous souhaitez discuter de vos besoins spécifiques avec notre équipe.</p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="wide-tb-80 bg-scroll bg-img-6 pos-rel callout-style-1">
                    <div className="bg-overlay blue opacity-60"></div>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4 col-md-12 mb-0 wow slideInUp" data-wow-duration="0" data-wow-delay="0.1s">
                                <h4 className="h4-xl">Interested in working with Logzee?</h4>
                            </div>
                            <div className="col wow slideInUp" data-wow-duration="0" data-wow-delay="0.2s">
                                <div className="center-text">
                                    We don’t just manage suppliers, we micro-manage them. We have a consultative, personalized approach
                                </div>
                            </div>
                            <div className="col-sm-auto wow slideInUp" data-wow-duration="0" data-wow-delay="0.3s">
                                <a href="#" className="btn btn-theme bg-white bordered">Get In Touch <i className="icofont-rounded-right"></i></a>
                            </div>
                        </div>
                    </div>
                </section> */}
            </main>
        </>
    );
};
export default Content;

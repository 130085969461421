import React from "react";

const Services = () => {
    return (
        <>
            <main id="body-content">
                <section className="bg-white wide-tb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 wow fadeInDown" data-wow-duration="0" data-wow-delay="0s">
                                <h1 className="heading-main">
                                    <span>Ce que nous offrons</span>
                                    Nos Services
                                </h1>
                            </div>
                            <div className="col-md-6 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.1s">
                                <a href="/servicedebours">
                                    <div className="icon-box-1">
                                        <img src="images/icon-box-1.jpg" alt="" />
                                        <div className="text">
                                            <i className="icofont-vehicle-delivery-van"></i>
                                            LES DEBOURS
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-6 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.4s">
                                <a href="/serviceprestations">
                                    <div className="icon-box-1">
                                        <img src="images/icon-box-2.jpg" alt="" />
                                        <div className="text">
                                            <i className="icofont-airplane-alt"></i>
                                            LES PRESTATIONS
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-sky-blue wide-tb-100">
                    <div className="container pos-rel">
                        <div className="row">
                            <div className="img-business-man">
                                <img src="images/courier-man.png" alt="" />
                            </div>
                            <div className="col-sm-12 wow fadeInDown" data-wow-duration="0" data-wow-delay="0s">
                                <h1 className="heading-main">
                                    <span>Notre expertise</span>
                                    Vous gagnerez
                                </h1>
                            </div>
                            <div className="col-md-6 ml-auto">
                                <div className="row">
                                    <div className="col-12 wow fadeInUp" data-wow-duration="0" data-wow-delay="0s">
                                        <div className="icon-box-3 mb-5 bg-sky-blue">
                                            <div className="media">
                                                <div className="service-icon mr-5">
                                                    <i className="icofont-box"></i>
                                                </div>
                                                <div className="service-inner-content media-body">
                                                    <h4 className="h4-md">En temps</h4>
                                                    <p>Vous réduirez le temps consacré au suivi de procédures de dédouanement de vos marchandises.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.2s">
                                        <div className="icon-box-3 mb-5 bg-sky-blue">
                                            <div className="media">
                                                <div className="service-icon mr-5">
                                                    <i className="icofont-shield"></i>
                                                </div>
                                                <div className="service-inner-content media-body">
                                                    <h4 className="h4-md">En tranquillité</h4>
                                                    <p>Vous serez désormais exempts des tracasseries douanières et de la lourdeur des procédures administratives dans les différentes administrations y relatives.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.4s">
                                        <div className="icon-box-3 bg-sky-blue">
                                            <div className="media">
                                                <div className="service-icon mr-5">
                                                    <i className="icofont-tree-alt"></i>
                                                </div>
                                                <div className="service-inner-content media-body">
                                                    <h4 className="h4-md">En fiabilité</h4>
                                                    <p>Le grand apport des Nouvelles Technologies de l’Informations et de la Communication dans notre mode de fonctionnement garantira la rapidité et la fiabilité de vos opérations ;La mise à jour de notre base de données des textes et règlements nationaux et internationaux garantira l’actualisation de notre expertise sur le plan juridique.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                <section className="bg-light-gray wide-tb-100 pb-0">
                    <div className="container-fluid p-0">
                        <div className="row align-items-center no-gutters">
                            <div className="col-lg-4 text-center">
                                <div className="px-5 wide-tb-100">
                                    <div className="service-icon mx-auto mb-5 icon-box-5">
                                        <i className="icofont-glass"></i>
                                    </div>
                                    <h4 className="h4-md fw-7 txt-blue">SÛR & SÉCURITAIRE</h4>
                                    Nous transportons vos marchandises en toute sécurité pour un service optimal.
                                </div>
                            </div>
                            <div className="col-lg-4 text-center bg-fixed clients-bg pos-rel txt-white">
                                <div className="bg-overlay black opacity-40"></div>
                                <div className="px-5 wide-tb-100" style={{ position: "relative", zIndex: "999" }}>
                                    <div className="service-icon mx-auto mb-5 icon-box-5">
                                        <i className="icofont-delivery-time"></i>
                                    </div>
                                    <h4 className="h4-md fw-7">LIVRAISON RAPIDE</h4>
                                    Notre temps de livraison et l'un des plus compétitifs sur le marché.
                                </div>
                            </div>
                            <div className="col-lg-4 text-center">
                                <div className="px-5 wide-tb-100">
                                    <div className="service-icon mx-auto mb-5 icon-box-5">
                                        <i className="icofont-live-support"></i>
                                    </div>
                                    <h4 className="h4-md fw-7 txt-blue">Support 24/7</h4>
                                    Nous sommes à vôtre disposition 24h/24 et 7 jours/7
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="wide-tb-100 mb-spacer-md">
                    <div className="container wide-tb-100 pb-0">
                        <div className="row">
                            <div className="col-sm-12 wow fadeInDown" data-wow-duration="0" data-wow-delay="0s">
                                <h1 className="heading-main">
                                    <span>Ce que nos</span>
                                    Clients disent
                                </h1>
                            </div>
                            <div className="col-sm-12">
                                <div className="owl-carousel owl-theme" id="home-client-testimonials">

                                    <div className="item">
                                        <div className="client-testimonial bg-wave">
                                            <div className="media">
                                                <div className="client-testimonial-icon rounded-circle bg-primary">
                                                    <img src="images/team_1.jpg" alt="" />
                                                </div>
                                                <div className="client-inner-content media-body">
                                                    <p>Un service très excellent, je recommande fortement</p>
                                                    <footer className="blockquote-footer"><cite title="Source Title">John Gerry  Design Hunt</cite></footer>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="client-testimonial bg-wave">
                                            <div className="media">
                                                <div className="client-testimonial-icon rounded-circle bg-primary">
                                                    <img src="images/team_2.jpg" alt="" />
                                                </div>
                                                <div className="client-inner-content media-body">
                                                    <p>Un service très excellent, je recommande fortement </p>
                                                    <footer className="blockquote-footer"><cite title="Source Title">John Gerry  Design Hunt</cite></footer>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="client-testimonial bg-wave">
                                            <div className="media">
                                                <div className="client-testimonial-icon rounded-circle bg-primary">
                                                    <img src="images/team_3.jpg" alt="" />
                                                </div>
                                                <div className="client-inner-content media-body">
                                                    <p>Un service très excellent, je recommande fortement</p>
                                                    <footer className="blockquote-footer"><cite title="Source Title">John Gerry  Design Hunt</cite></footer>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                <section className="wide-tb-100 bg-sky-blue pos-rel">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 wow fadeInDown" data-wow-duration="0" data-wow-delay="0s">
                                <h1 className="heading-main">
                                    <span>Quelques de nos</span>
                                    Clients
                                </h1>
                            </div>
                            <div className="col-sm-12 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.2s">
                                <div className="owl-carousel owl-theme" id="home-clients">
                                    <div className="item">
                                        <img src="images/clients/africacontainershipping.jpg" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="images/clients/asecna.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="images/clients/btc.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="images/clients/gulfcam.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="images/clients/moleculesgas.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="images/clients/novaplast.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="images/clients/parlym.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="wide-tb-80 bg-scroll bg-img-6 pos-rel callout-style-1">
                    <div className="bg-overlay blue opacity-60"></div>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4 col-md-12 mb-0">
                                <h4 className="h4-xl">Interested in working with Logzee?</h4>
                            </div>
                            <div className="col">
                                <div className="center-text">
                                    We don’t just manage suppliers, we micro-manage them. We have a consultative, personalized approach
                                </div>
                            </div>
                            <div className="col-sm-auto">
                                <a href="#" className="btn btn-theme bg-white bordered">Get In Touch <i className="icofont-rounded-right"></i></a>
                            </div>
                        </div>
                    </div>
                </section> */}
            </main>
        </>
    );
};
export default Services;

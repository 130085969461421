import React from "react";

const ServicePrestations = () => {
    return (
        <>
            <main id="body-content">
                <section className="wide-tb-80">
                    <div className="container pos-rel">
                        <div className="row align-items-start">
                            <div className="col-md-12 col-lg-8">
                                <div className="row">
                                    <div className="col-md-12 mb-5">
                                        <div className="text-left">
                                            <img src="images/prestations.jpg" alt="" className="rounded mb-4" />
                                            <h4 className="h4-md mb-3 txt-blue fw-7">Les PRESTATIONS</h4>
                                            <p>Cette rubrique comprend :</p>
                                            <ul className="list-unstyled icons-listing theme-orange mb-0">
                                                <li><i className="icofont-check"></i><b>Les frais de dossiers :</b> Ils sont fixés forfaitairement FCFA 25.000 (Vingt-cinq mille) par 
                                                dossier à l’IMPORT et FCFA 35.000 (trente-cinq mille) par dossier à l’EXPORT 
                                                <p class="mb-0">Ces frais couvrent : les différents coûts relatifs à l’informatique (coût d’intervention, consommables, machine, ruban, papiers etc…) ;</p>
                                                </li>
                                                <li><i className="icofont-check"></i><b>Les commissions sur débours :</b> Une commission de 2% par mois sera facturée pour les débours non provisionnés ;</li>
                                                <li><i className="icofont-check"></i><b>Les commissions sur Transit :</b> Une commission de FCFA 629FCFA (six cent vingt-neuf francs CFA) sera facturée par
                                                    tonne, conformément aux documents de transport (BL ou LTA) ;</li>
                                                <li><i className="icofont-check"></i><b>Honoraire d’Agréé en Douane :</b> Les honoraires vous seront facturés suivant le barème 
                                                officiel, tel que décliné ci-dessous :
                                                    <ul className="list-unstyled icons-listing theme-orange mb-0 ml-2 mt-3 font-weight-bold">
                                                        <li><i className="icofont-dotted-right"></i>Moins de 1.000.000 FCFA : 50.000 FCFA</li>
                                                        <li><i className="icofont-dotted-right"></i>De 1.000.001 à 2.000.000 FCFA : 80.000 FCFA</li>
                                                        <li><i className="icofont-dotted-right"></i>De 2.000.001 à 6.000.000 FCFA : 163.000 FCFA</li>
                                                        <li><i className="icofont-dotted-right"></i>De 6.000.001 à 10.000.000 FCFA : 240.000 FCFA</li>
                                                        <li class="pb-0"><i className="icofont-dotted-right"></i>Au-dessus de 10.000.001 FCFA : 245.000 + 0.5% de la valeur CAF</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                            
                                            <p> Nous ne pourrons exécuter vos instructions qu’à compter de la date de réception d’un Ordre de Transit dûment visé et cacheté par vos bons soins.</p>
                                            <p> Afin de vous offrir des prestations de qualité dans les meilleurs délais, tous les documents nécessaires
                                                aux formalités douanières doivent nous parvenir (10 Jrs) dix jours avant l’arrivée du navire au port de
                                                Douala ou Kribi, et (48 H) quarante-huit heures avant l’arrivée du vol à l’aéroport de Douala ou
                                                Yaoundé
                                            </p>
                                            <ul className="list-unstyled icons-listing theme-orange mb-0 ml-2 font-weight-bold">
                                                <li><i className="icofont-dotted-right"></i>BL originaux, colisage, facture fournisseurs, facture fret. </li>
                                                <li><i className="icofont-dotted-right"></i>Déclaration d’importation, carte de contribuable, BESC </li>
                                                <li><i className="icofont-dotted-right"></i>Ordre de Transit dument complété </li>
                                                <li><i className="icofont-dotted-right"></i>Ordre de Transit, tamponné et signée pour acceptation </li>
                                            </ul>
                                            <p> Les pénalités éventuelles (stationnements, surestaries), dues à la non-réception de l’ensemble des
                                                documents nécessaires au dédouanement, ne sauraient nous être imputables.
                                                Toutes nos factures sont émises en CFA avec parité fixe 1 €uro = 655.957 FCFA. 
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-4">
                                <aside className="sidebar-spacer row">
                                    <div className="sidebar-primary col-lg-12 col-md-6">
                                        {/* <div className="widget-wrap">
                                            <h3 className="h3-md fw-7 mb-4">Search</h3>
                                            <form className="flex-nowrap col ml-auto footer-subscribe p-0">
                                                <input type="text" className="form-control" placeholder="Search …" />
                                                <button type="submit" className="btn btn-theme bg-secondary"><i className="icofont-search p-0"></i></button>
                                            </form>
                                        </div> */}
                                        {/* <div className="widget-wrap">
                                            <h3 className="h3-md fw-7 mb-4">Recent Posts</h3>
                                            <div className="blog-list-footer">
                                                <ul className="list-unstyled">
                                                    <li>
                                                        <div className="media">
                                                            <div className="post-thumb">
                                                                <img src="images/post_thumb_1.jpg" alt="" className="rounded-circle" />
                                                            </div>
                                                            <div className="media-body post-text">
                                                                <h5 className="mb-3 h5-md"><a href="#">Liberalisation of air cargo</a></h5>
                                                                <p>Far far away, behind the word mountains, far from the countries.</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="media">
                                                            <div className="post-thumb">
                                                                <img src="images/post_thumb_2.jpg" alt="" className="rounded-circle" />
                                                            </div>
                                                            <div className="media-body post-text">
                                                                <h5 className="mb-3 h5-md"><a href="#">New Ocean Freight Rules</a></h5>
                                                                <p>Far far away, behind the word mountains, far from the countries.</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div> */}
                                        <div className="widget-wrap text-center bg-sky-blue rounded py-5">
                                            <div className="mb-2"><i className="icofont-headphone-alt icofont-4x"></i></div>
                                            <h3 className="h3-md fw-5 txt-orange mb-4">Besoin d'aide?</h3>
                                            <p>Contactez notre<br /> support technique 24/7</p>
                                            <a href="/contact" className="btn-theme bg-secondary mt-3">Contacter <i className="icofont-rounded-right"></i></a>
                                        </div>
                                    </div>
                                    <div className="sidebar-secondary col-lg-12 col-md-6">
                                        <div className="widget-wrap">
                                            <h3 className="h3-md fw-7 mb-4">Nos services</h3>
                                            <div className="blog-list-categories">
                                                <ul className="list-unstyled">
                                                    <li><a href="/servicedebours"> Les Debours</a></li>
                                                    <li className="active"><a href="/serviceprestations">Les Prestations</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="widget-wrap">
                                            <h3 className="h3-md fw-7 mb-4">Notre Photothèque</h3>
                                            <ul className="photo-thumbs">
                                                <li><a href="/images/portfolio/original-images/3col-1.jpg" target="_blank">
                                                    <img src="/images/portfolio/original-images/3col-1.jpg" alt="image-1" /></a>
                                                </li>
                                                <li><a href="/images/portfolio/original-images/3col-2.jpg" target="_blank">
                                                    <img src="/images/portfolio/original-images/3col-2.jpg" alt="image-1" /></a>
                                                </li>
                                                <li><a href="/images/portfolio/original-images/3col-3.jpg" target="_blank">
                                                    <img src="/images/portfolio/original-images/3col-3.jpg" alt="image-1" /></a>
                                                </li>
                                                <li><a href="/images/portfolio/original-images/3col-4.jpg" target="_blank">
                                                    <img src="/images/portfolio/original-images/3col-4.jpg" alt="image-1" /></a>
                                                </li>
                                                <li><a href="/images/portfolio/original-images/3col-5.jpg" target="_blank">
                                                    <img src="/images/portfolio/original-images/3col-5.jpg" alt="image-1" /></a>
                                                </li>
                                                <li><a href="/images/portfolio/original-images/3col-6.jpg" target="_blank">
                                                    <img src="/images/portfolio/original-images/3col-6.jpg" alt="image-1" /></a>
                                                </li>
                                                <li><a href="/images/portfolio/original-images/3col-7.jpg" target="_blank">
                                                    <img src="/images/portfolio/original-images/3col-7.jpg" alt="image-1" /></a>
                                                </li>
                                                <li><a href="/images/portfolio/original-images/3col-8.jpg" target="_blank">
                                                    <img src="/images/portfolio/original-images/3col-8.jpg" alt="image-1" /></a>
                                                </li>
                                                <li><a href="/images/portfolio/original-images/3col-9.jpg" target="_blank">
                                                    <img src="/images/portfolio/original-images/3col-9.jpg" alt="image-1" /></a>
                                                </li>
                                            </ul>
                                        </div>
                                        {/* <div className="widget-wrap">
                                            <h3 className="h3-md fw-7 mb-4">Text Widget</h3>
                                            <p>Explain to you how all this mistaken idea of hovered link and praising pain was born and I will give you a complete count of the system expound</p>
                                        </div> */}
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="wide-tb-80 bg-scroll bg-img-6 pos-rel callout-style-1">
                    <div className="bg-overlay blue opacity-60"></div>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4 col-md-12 mb-0">
                                <h4 className="h4-xl">Interested in working with Logzee?</h4>
                            </div>
                            <div className="col">
                                <div className="center-text">
                                    We don’t just manage suppliers, we micro-manage them. We have a consultative, personalized approach
                                </div>
                            </div>
                            <div className="col-sm-auto">
                                <a href="#" className="btn btn-theme bg-white bordered">Get In Touch <i className="icofont-rounded-right"></i></a>
                            </div>
                        </div>
                    </div>
                </section> */}
            </main>
        </>
    );
};
export default ServicePrestations;

import React, { useState } from 'react';

const Content = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`http://localhost:3002/send-email`, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                alert('Message Envoyé Avec Succès!');
                setFormData({
                name: '',
                email: '',
                phone: '',
                subject: '',
                message: ''
                });
            } else {
                const errorData = await response.json();
                alert('Error: ' + errorData.message);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Nous rencontrons une erreur, réessayez plustard!');
        }
    };
    return (
        <>
            <main id="body-content">
                <section className="wide-tb-80 contact-full-shadow">
                    <div className="container">
                        <div className="contact-map-bg">
                            <img src="images/map-bg.png" alt="" />
                        </div>
                        <div className="row justify-content-between">
                            <div className="col-md-6 col-sm-12 col-lg-4 wow fadeInRight" data-wow-duration="0" data-wow-delay="0s">
                                <div className="contact-detail-shadow">
                                    <h4>Douala, Cameroun</h4>
                                    <div className="d-flex align-items-start items">
                                        <i className="icofont-google-map"></i> <span>Situé à 510, RUE JOFFRE AKWA, DOUALA</span>
                                    </div>
                                    <div className="d-flex align-items-start items">
                                        <i className="icofont-phone"></i> <span> +237 690 768 400</span>
                                    </div>
                                    <div className="text-nowrap d-flex align-items-start items">
                                        <i className="icofont-email"></i> <a href="#">info@log2trans.com</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 col-lg-4 wow fadeInLeft" data-wow-duration="0" data-wow-delay="0s">
                                <div className="contact-detail-shadow">
                                    <h4>Yaoundé, Cameroun</h4>
                                    <div className="d-flex align-items-start items">
                                        <i className="icofont-google-map"></i> <span>Situé à Montée Jouvence, Face Santa Lucia</span>
                                    </div>
                                    <div className="d-flex align-items-start items">
                                        <i className="icofont-phone"></i> <span>+237 677 33 71 69</span>
                                    </div>
                                    <div className="text-nowrap d-flex align-items-start items">
                                        <i className="icofont-email"></i> <a href="#">info@log2trans.com</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wide-tb-100 bg-light-gray pt-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-lg-8 offset-lg-2 wow fadeInUp" data-wow-duration="0" data-wow-delay="0s">
                                <div className="free-quote-form contact-page">
                                    <h1 className="heading-main mb-4">
                                        Nous Contacter
                                    </h1>
                                    <form onSubmit={handleSubmit} method="post" id="contactusForm" className="col rounded-field">
                                        <div className="form-row mb-4">
                                            <div className="col">
                                                <input type="text" name="name" id="name" required className="form-control" value={formData.name} onChange={handleChange} placeholder="Votre Nom" />
                                            </div>
                                            <div className="col">
                                                <input type="text" name="email" id="email" required className="form-control" value={formData.email} onChange={handleChange} placeholder="Email" />
                                            </div>
                                        </div>
                                        <div className="form-row mb-4">
                                            <div className="col">
                                                <input type="text" name="phone" id="phone" className="form-control" value={formData.phone} onChange={handleChange} placeholder="Numéro de téléphone" />
                                            </div>
                                            <div className="col">
                                                <input type="text" name="subject" id="subject" required className="form-control" value={formData.subject} onChange={handleChange} placeholder="Sujet" />
                                            </div>
                                        </div>
                                        <div className="form-row mb-4">
                                            <div className="col">
                                                <textarea rows="7" name="message" id="message" required placeholder="Message" value={formData.message} onChange={handleChange} className="form-control"></textarea>
                                            </div>
                                        </div>
                                        <div className="form-row text-center">
                                            <button name="contactForm" id="contactForm" type="submit" className="form-btn mx-auto btn-theme bg-secondary">Envoyer <i className="icofont-rounded-right"></i></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="map-bg">
                    <div id="map-holder" className="pos-rel">
                        <div id="map_extended">
                            <p>This will be replaced with the Google Map.</p>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};
export default Content;

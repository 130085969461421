import React from "react";

const ServiceDebours = () => {
    return (
        <>
            <main id="body-content">
                <section className="wide-tb-80">
                    <div className="container pos-rel">
                        <div className="row align-items-start">
                            <div className="col-md-12 col-lg-8">
                                <div className="row">
                                    <div className="col-md-12 mb-5">
                                        <div className="text-left">
                                            <img src="images/debours.jpg" alt="" className="rounded mb-4" />
                                            <h4 className="h4-md mb-3 txt-blue fw-7">Les DEBOURS</h4>
                                            <p>Facturables à l’identique et sur justificatifs, ils comprennent sans être exhaustifs</p>
                                            <ul className="list-unstyled icons-listing theme-orange mb-0">
                                                <li><i className="icofont-check"></i>Les frais éventuels de visite ;</li>
                                                <li><i className="icofont-check"></i>Les frais de BESC si nécessaire ;</li>
                                                <li><i className="icofont-check"></i>Les frais de déchargement et de dépotage à Douala ou autre destination de votre choix ;</li>
                                                <li><i className="icofont-check"></i>Les frais éventuels d’expertise ;</li>
                                                <li><i className="icofont-check"></i>Les frais fixe par BL ;</li>
                                                <li><i className="icofont-check"></i>Les frais de stationnement, surestaries sur Terminal & Location/ Détention à la sortie de
                                                    port conteneur au tarif officiel ;</li>
                                                <li><i className="icofont-check"></i>Toutes autres prestations non listées.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <div className="col-sm-6">
                                        <h3 className="h3-md fw-7 mb-4 txt-blue">Benefit of Service</h3>
                                        <ul className="list-unstyled icons-listing theme-orange mb-0">
                                            <li><i className="icofont-check"></i>Deliver Environmentally Responsible Client Services</li>
                                            <li><i className="icofont-check"></i>Be an Active Community Partner</li>
                                            <li><i className="icofont-check"></i>Drive Continuous Improvement</li>
                                            <li><i className="icofont-check"></i>Clearance and compliance service</li>
                                            <li><i className="icofont-check"></i>Clearance and compliance service</li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-6">
                                        <img src="images/blog_img_4.jpg" alt="" className="rounded mb-4" />
                                    </div>
                                    <div className="col-sm-12 mb-4">
                                        <h3 className="h3-md fw-7 mb-4 txt-blue">More information</h3>
                                        <div className="theme-collapse arrow-right-border">
                                            <div className="toggle">
                                                <i className="icofont-rounded-down"></i> What is do i have to tell you a few lorem?
                                            </div>
                                            <div className="collapse">
                                                <div className="content">
                                                    Nunc non mollis nulla. Sed tconsectetur
                                                   elit id mi consectetur bibendum. Aenean
                                                   sollicitudin. Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis. Vivamus ac ultrices diam,
                                                </div>
                                            </div>
                                            <div className="toggle">
                                                <i className="icofont-rounded-down"></i> I have a technical problem or support issue I need resolved, who do I email?
                                            </div>
                                            <div className="collapse">
                                                <div className="content">
                                                    Nunc non mollis nulla. Sed tconsectetur
                                                   elit id mi consectetur bibendum. Aenean
                                                   sollicitudin. Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis. Vivamus ac ultrices diam,
                                                </div>
                                            </div>
                                            <div className="toggle">
                                                <i className="icofont-rounded-down"></i> What other services are you compatible with?
                                            </div>
                                            <div className="collapse">
                                                <div className="content">
                                                    Nunc non mollis nulla. Sed tconsectetur
                                                   elit id mi consectetur bibendum. Aenean
                                                   sollicitudin. Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis. Vivamus ac ultrices diam,
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 mb-4">
                                        <h3 className="h3-md fw-7 mb-4">Still open questions?</h3>
                                        <a href="#" className="mr-2 mb-3 btn-theme bg-secondary">Ask Now <i className="icofont-rounded-right"></i></a>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-4">
                                <aside className="sidebar-spacer row">
                                    <div className="sidebar-primary col-lg-12 col-md-6">
                                        {/* <div className="widget-wrap">
                                            <h3 className="h3-md fw-7 mb-4">Search</h3>
                                            <form className="flex-nowrap col ml-auto footer-subscribe p-0">
                                                <input type="text" className="form-control" placeholder="Search …" />
                                                <button type="submit" className="btn btn-theme bg-secondary"><i className="icofont-search p-0"></i></button>
                                            </form>
                                        </div> */}
                                        {/* <div className="widget-wrap">
                                            <h3 className="h3-md fw-7 mb-4">Recent Posts</h3>
                                            <div className="blog-list-footer">
                                                <ul className="list-unstyled">
                                                    <li>
                                                        <div className="media">
                                                            <div className="post-thumb">
                                                                <img src="images/post_thumb_1.jpg" alt="" className="rounded-circle" />
                                                            </div>
                                                            <div className="media-body post-text">
                                                                <h5 className="mb-3 h5-md"><a href="#">Liberalisation of air cargo</a></h5>
                                                                <p>Far far away, behind the word mountains, far from the countries.</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="media">
                                                            <div className="post-thumb">
                                                                <img src="images/post_thumb_2.jpg" alt="" className="rounded-circle" />
                                                            </div>
                                                            <div className="media-body post-text">
                                                                <h5 className="mb-3 h5-md"><a href="#">New Ocean Freight Rules</a></h5>
                                                                <p>Far far away, behind the word mountains, far from the countries.</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div> */}
                                        <div className="widget-wrap text-center bg-sky-blue rounded py-5">
                                            <div className="mb-2"><i className="icofont-headphone-alt icofont-4x"></i></div>
                                            <h3 className="h3-md fw-5 txt-orange mb-4">Besoin d'aide?</h3>
                                            <p>Contactez notre<br /> support technique 24/7</p>
                                            <a href="/contact" className="btn-theme bg-secondary mt-3">Contacter <i className="icofont-rounded-right"></i></a>
                                        </div>
                                    </div>
                                    <div className="sidebar-secondary col-lg-12 col-md-6">
                                        <div className="widget-wrap">
                                            <h3 className="h3-md fw-7 mb-4">Nos services</h3>
                                            <div className="blog-list-categories">
                                                <ul className="list-unstyled">
                                                    <li className="active"><a href="/servicedebours"> Les Debours</a></li>
                                                    <li><a href="/serviceprestations">Les Prestations</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="widget-wrap">
                                            <h3 className="h3-md fw-7 mb-4">Notre Photothèque</h3>
                                            <ul className="photo-thumbs">
                                                <li><a href="/images/portfolio/original-images/3col-1.jpg" target="_blank">
                                                    <img src="/images/portfolio/original-images/3col-1.jpg" alt="image-1" /></a>
                                                </li>
                                                <li><a href="/images/portfolio/original-images/3col-2.jpg" target="_blank">
                                                    <img src="/images/portfolio/original-images/3col-2.jpg" alt="image-1" /></a>
                                                </li>
                                                <li><a href="/images/portfolio/original-images/3col-3.jpg" target="_blank">
                                                    <img src="/images/portfolio/original-images/3col-3.jpg" alt="image-1" /></a>
                                                </li>
                                                <li><a href="/images/portfolio/original-images/3col-4.jpg" target="_blank">
                                                    <img src="/images/portfolio/original-images/3col-4.jpg" alt="image-1" /></a>
                                                </li>
                                                <li><a href="/images/portfolio/original-images/3col-5.jpg" target="_blank">
                                                    <img src="/images/portfolio/original-images/3col-5.jpg" alt="image-1" /></a>
                                                </li>
                                                <li><a href="/images/portfolio/original-images/3col-6.jpg" target="_blank">
                                                    <img src="/images/portfolio/original-images/3col-6.jpg" alt="image-1" /></a>
                                                </li>
                                                <li><a href="/images/portfolio/original-images/3col-7.jpg" target="_blank">
                                                    <img src="/images/portfolio/original-images/3col-7.jpg" alt="image-1" /></a>
                                                </li>
                                                <li><a href="/images/portfolio/original-images/3col-8.jpg" target="_blank">
                                                    <img src="/images/portfolio/original-images/3col-8.jpg" alt="image-1" /></a>
                                                </li>
                                                <li><a href="/images/portfolio/original-images/3col-9.jpg" target="_blank">
                                                    <img src="/images/portfolio/original-images/3col-9.jpg" alt="image-1" /></a>
                                                </li>
                                            </ul>
                                        </div>
                                        {/* <div className="widget-wrap">
                                            <h3 className="h3-md fw-7 mb-4">Text Widget</h3>
                                            <p>Explain to you how all this mistaken idea of hovered link and praising pain was born and I will give you a complete count of the system expound</p>
                                        </div> */}
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="wide-tb-80 bg-scroll bg-img-6 pos-rel callout-style-1">
                    <div className="bg-overlay blue opacity-60"></div>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4 col-md-12 mb-0">
                                <h4 className="h4-xl">Interested in working with Logzee?</h4>
                            </div>
                            <div className="col">
                                <div className="center-text">
                                    We don’t just manage suppliers, we micro-manage them. We have a consultative, personalized approach
                                </div>
                            </div>
                            <div className="col-sm-auto">
                                <a href="#" className="btn btn-theme bg-white bordered">Get In Touch <i className="icofont-rounded-right"></i></a>
                            </div>
                        </div>
                    </div>
                </section> */}
            </main>
        </>
    );
};
export default ServiceDebours;

import React from "react";

const Footer = () => {
	return (
		<>
			{/* footer start */}
			<footer className="wide-tb-70 bg-light-gray pb-0">
				<div className="container">
					<div className="row">

						<div className="col-lg-6 col-md-6 wow fadeInLeft" data-wow-duration="0" data-wow-delay="0s">
							<div className="logo-footer">
								<img src="images/logo.jpeg" alt="" />
							</div>
							<p>La Société LOG 2 TRANS est une filiale de KIMORHAR INVESTMENT GROUP.</p>
							<p>Notre force est de pouvoir s'associer, si besoin, aux compétences des sociétés partenaires spécialisées..</p>

							<h3 className="footer-heading">Nos comptes</h3>
							<div className="social-icons">
								<a href="#"><i className="icofont-facebook"></i></a>
								<a href="#"><i className="icofont-twitter"></i></a>
								<a href="#"><i className="icofont-whatsapp"></i></a>
								<a href="#"><i className="icofont-google-plus"></i></a>
							</div>
						</div>

						<div className="col-lg-6 col-md-12 wow fadeInLeft" data-wow-duration="0" data-wow-delay="0.4s">
							<h3 className="footer-heading">Notre Photothèque</h3>
							<ul className="photo-thumbs">
								<li><a href="/images/portfolio/original-images/3col-1.jpg" target="_blank">
									<img src="/images/portfolio/original-images/3col-1.jpg" alt="image-1" /></a>
								</li>
								<li><a href="/images/portfolio/original-images/3col-2.jpg" target="_blank">
									<img src="/images/portfolio/original-images/3col-2.jpg" alt="image-1" /></a>
								</li>
								<li><a href="/images/portfolio/original-images/3col-3.jpg" target="_blank">
									<img src="/images/portfolio/original-images/3col-3.jpg" alt="image-1" /></a>
								</li>
								<li><a href="/images/portfolio/original-images/3col-4.jpg" target="_blank">
									<img src="/images/portfolio/original-images/3col-4.jpg" alt="image-1" /></a>
								</li>
								<li><a href="/images/portfolio/original-images/3col-5.jpg" target="_blank">
									<img src="/images/portfolio/original-images/3col-5.jpg" alt="image-1" /></a>
								</li>
								<li><a href="/images/portfolio/original-images/3col-6.jpg" target="_blank">
									<img src="/images/portfolio/original-images/3col-6.jpg" alt="image-1" /></a>
								</li>
								<li><a href="/images/portfolio/original-images/3col-7.jpg" target="_blank">
									<img src="/images/portfolio/original-images/3col-7.jpg" alt="image-1" /></a>
								</li>
								<li><a href="/images/portfolio/original-images/3col-8.jpg" target="_blank">
									<img src="/images/portfolio/original-images/3col-8.jpg" alt="image-1" /></a>
								</li>
								<li><a href="/images/portfolio/original-images/3col-9.jpg" target="_blank">
									<img src="/images/portfolio/original-images/3col-9.jpg" alt="image-1" /></a>
								</li>
							</ul>
						</div>

					</div>
				</div>

				<div className="copyright-wrap bg-primary wide-tb-30">
					<div className="container">
						<div className="row text-md-left text-center">
							<div className="col-sm-12 col-md-6 copyright-links">
								<a href="/">Politique de Confidentialité</a>   <span>|</span>   <a href="/contact">CONTACT</a>   <span>|</span>   <a href="/">FAQS</a>
							</div>
							<div className="col-sm-12 col-md-6 text-md-right text-center">
								Designed by <a href="/" rel="nofollow">Log2Trans</a> © 2024 All Rights Reserved
            				</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
};

export default Footer;
